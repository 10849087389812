<template>
  <CRow class="configure settings">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <div v-if="loadingData">
        <CRow>
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
          </CCol>
        </CRow>
      </div>
      <div v-if="!loadingData">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pb-0">
            <CRow class="target pl-0 pr-0">
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                <span>{{ $t('sq.Send_every') }}</span>            
              </CCol>
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                <quick-edit type="number" step="1" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="frequency.sq_frequency_weeks" v-on:input="updateFrequency()">                                  
                  <span v-if="frequency.sq_frequency_weeks">{{frequency.sq_frequency_weeks == 1 ? $t('common.week') : frequency.sq_frequency_weeks + ' ' + $t('common.weeks')}}<i class="icon edit fas fa-pen ml-1"/></span>
                  <span v-else>{{$t('sq.Click_to_set_weekly_frequency')}}<i class="icon edit fas fa-pen ml-1"/></span>
                </quick-edit>
              </CCol>                  
            </CRow>
            <CRow class="target pl-0 pr-0">
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                <span>{{ $t('sq.Number_of_satisquestions') }}</span>
              </CCol>
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                <quick-edit type="number" step="1" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="frequency.sq_questions_per_frequency" v-on:input="updateFrequency()">
                  <span v-if="frequency.sq_questions_per_frequency">{{frequency.sq_questions_per_frequency}} {{frequency.sq_questions_per_frequency == 1 ? $t('sq.satisquestion') : $t('sq.satisquestions')}}<i class="icon edit fas fa-pen ml-1"/></span>
                  <span v-else>{{$t('sq.Click_to_set_number_of_satisquestions')}}<i class="icon edit fas fa-pen ml-1"/></span>
                </quick-edit>
              </CCol>                  
            </CRow>
          </CCol>
        </CRow>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'Cycle',
  components: {
    QuickEdit,
    loadingSpinner
  },
  data() {
    return {
      frequency: {
        sq_frequency_weeks: 0,
        sq_questions_per_frequency: 0
      },
      loadingData: false
    }
  },
  methods: {
    getFrequency(showLoader) {
      if(showLoader) this.loadingData = true;

      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/frequency')
      .then(res => {
        this.frequency = res.data.data;
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateFrequency() {
      let params = {};

      if(this.frequency.sq_frequency_weeks <= 0) this.frequency.sq_frequency_weeks = 1;
      if(this.frequency.sq_questions_per_frequency <= 0) this.frequency.sq_questions_per_frequency = 1;

      params.sq_frequency_weeks = this.frequency.sq_frequency_weeks;
      params.sq_questions_per_frequency = this.frequency.sq_questions_per_frequency;

      axios.put(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/frequency', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('sq.Frequency_updated'), type: 'is-success', duration: 2000 });
        // Get the frequency       
        this.getFrequency();        
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    }
  },
  mounted: function(){
    this.getFrequency(true);
  }
}
</script>
