<template>
  <CRow class="configure settings">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <div v-if="loadingData">
        <CRow>
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
          </CCol>
        </CRow>
      </div>
      <div v-if="!loadingData">
        <CRow v-if="targets.overall_score && targets.participation" class="m-0">
          <CCol cols="12" lg="12">
            <CRow>
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                <div class="h-100 d-flex align-items-center">
                  <span>{{$t('sq.Overall_rating')}}</span>
                </div>
              </CCol>
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                <quick-edit type="text" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="targets.overall_score.value" v-on:input="updateTarget(targets.overall_score.target_id_external, targets.overall_score)">
                  <span class="integral">{{ getScoreIntegral(targets.overall_score.value) }}</span>
                  <span class="decimal">.</span>
                  <span class="fractional">{{ getScoreFracetional(targets.overall_score.value) }}</span>
                  <i class="icon edit fas fa-pen ml-1"/>
                </quick-edit>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0 pb-0">
                <div class="h-100 d-flex align-items-center">
                  <span>{{$t('sq.Percentage_participation')}}</span>
                </div>
              </CCol>
              <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0 pb-0">
                <quick-edit type="text" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="targets.participation.value" v-on:input="updateTarget(targets.participation.target_id_external, targets.participation)">
                  <span class="integral">{{targets.participation.value}}</span>
                  <span class="fractional">%</span>
                  <i class="icon edit fas fa-pen ml-1"/>
                </quick-edit>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow v-else class="m-0">
          <CCol cols="12" lg="12" class="pb-0">
            <label class="d-block"><b>{{$t('sq.No_targets_set')}}</b></label>
            <CButton class="m-0" color="primary" @click="setDefaultCompanyTargets();">
              <span>{{$t('sq.Set_default_targets')}}<i class="fas fa-chevron-right ml-1"/></span>
            </CButton>
          </CCol>
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" v-bind:class="{ 'pb-0' : targets.category_scores.length > 0 }">
            <CRow>
              <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                <span><b>{{ $t('sq.Targets_pillars') }}</b></span>
              </CCol>
              <CCol cols="6" xl="6" class="pt-0 pb-0 text-right">
                <span class="pointer" @click="openSidebarRight('new_target', { target_type_tag: 'category' });"><i class="fas fa-plus mr-1"/>{{ $t('sq.New_target') }}</span>
              </CCol>
            </CRow>
            <hr class="mt-2 mb-2">
            <div v-if="targets.category_scores.length > 0">
              <CRow v-for="category in targets.category_scores" v-bind:key="category.sq_category_id">
                <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                  <div class="h-100 w-100 d-table">
                    <span class="d-table-cell align-middle">{{category.category_name}}</span>
                  </div>
                </CCol>
                <CCol cols="4" xl="4" lg="4" md="4" sm="4" class="pt-0">
                  <div class="h-100 w-100 d-table">
                    <quick-edit class="d-table-cell align-middle" type="text" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="category.value" v-on:input="updateTarget(category.target_id_external, category)">                  
                      <span class="integral">{{ getScoreIntegral(category.value) }}</span>
                      <span class="decimal">.</span>
                      <span class="fractional">{{ getScoreFracetional(category.value) }}</span>
                      <i class="icon edit fas fa-pen ml-1"/>
                    </quick-edit>
                  </div>
                </CCol>
                <CCol cols="2" xl="2" lg="2" md="2" sm="2" class="pt-0 text-right">
                  <CButton color="primary" @click="confirmModal = true; confirmModalData = category;"><i class="fas fa-ban"/></CButton>
                </CCol>
              </CRow>
            </div>
            <div v-else>
              <span>{{ $t('measure.No_targets_pillars') }}</span>
            </div>
          </CCol>
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" v-bind:class="{ 'pb-0' : targets.category_scores.length > 0 }">
            <CRow>
              <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                <span><b>{{ $t('sq.Targets_subpillars') }}</b></span>
              </CCol> 
              <CCol cols="6" xl="6" class="pt-0 pb-0 text-right">
                <span class="pointer" @click="openSidebarRight('new_target', { target_type_tag: 'subcategory' });"><i class="fas fa-plus mr-1"/>{{ $t('sq.New_target') }}</span>
              </CCol>
            </CRow>
            <hr class="mt-2 mb-2">
            <div v-if="targets.subcategory_scores.length > 0">
              <CRow class="pl-0 pr-0" v-for="subcategory in targets.subcategory_scores" v-bind:key="subcategory.sq_subcategory_id">
                <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                  <div class="h-100 d-table">
                    <p class="d-table-cell align-middle">{{subcategory.subcategory_name}}</p>
                  </div>
                </CCol>
                <CCol cols="4" xl="4" lg="4" md="4" sm="4" class="pt-0">
                  <div class="h-100 d-table">
                    <quick-edit class="d-table-cell align-middle" type="text" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="subcategory.value" v-on:input="updateTarget(subcategory.target_id_external, subcategory)">
                      <span class="integral">{{ getScoreIntegral(subcategory.value) }}</span>
                      <span class="decimal">.</span>
                      <span class="fractional">{{ getScoreFracetional(subcategory.value) }}</span>
                      <i class="icon edit fas fa-pen ml-1"/>
                    </quick-edit>
                  </div>
                </CCol>
                <CCol cols="2" xl="2" lg="2" md="2" sm="2" class="pt-0 text-right">
                  <CButton color="primary" @click="confirmModal = true; confirmModalData = subcategory;"><i class="fas fa-ban"/></CButton>
                </CCol>
              </CRow>
            </div>
            <div v-else>
              <span>{{ $t('measure.No_targets_subpillars') }}</span>
            </div>
          </CCol>
        </CRow>
      </div>
    </CCol>

    <b-modal class="target" :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{ $t('sq.Confirm_deactivation_target') }} {{confirmModalData.category_name != undefined ? confirmModalData.category_name : confirmModalData.subcategory_name }}
        </CCardHeader>
        <CCardBody>
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p>{{ $t('sq.Sure_to_deactivate') }}</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="deactivateTarget(confirmModalData.target_id_external)"><i class="fas fa-check mr-1"/>{{ $t('Confirm') }}</CButton>
          <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('Cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'Targets',
  components: {
    QuickEdit,
    loadingSpinner
  },
  data() {
    return {
      targets: {
        overall_score: { value: 0 },
        participation: { value: 0 },
        category_scores: [],
        subcategory_scores: []
      },
      loadingData: false,
      confirmModal: false,
      confirmModalData: {},
    }
  },
  methods: {
    setDefaultCompanyTargets() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/measure/targets/default')
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('sq.Default_targets_set'), type: 'is-success', duration: 2000 });
        // Update the targets
        this.getTargets(false);
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    getTargets(showLoader) {
      if(showLoader) this.loadingData = true;

      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/targets')
      .then(res => {
        this.targets = res.data.data;
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateTarget(targetIdExternal, data) { 
      let params = data;

      axios.put(process.env.VUE_APP_API_URL + '/v1/measure/target/' + targetIdExternal, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('sq.Target_updated'), type: 'is-success', duration: 2000 });
        // Gather the targets       
        this.getTargets(false);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    deactivateTarget(targetIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/measure/target/' + targetIdExternal + '/deactivate')
      .then(res => {      
        this.$buefy.toast.open({ message: this.$t('sq.Target_deactivated'), type: 'is-success', duration: 2000 });
        this.confirmModal = false;
        // Gather the targets
        this.getTargets();       
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getScoreIntegral(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(0, score.indexOf('.'));
      } else {
        score = score.toFixed(1).toString();
        return score.substring(0, score.indexOf('.'));
      }
    },
    getScoreFracetional(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(score.indexOf('.') + 1);
      } else {
        score = score.toFixed(1).toString();
        return score.substring(score.indexOf('.') + 1);
      }
    },
  },
  mounted: function(){
    this.getTargets(true);

    this.$bus.$on('update_targets', () => {
      this.getTargets(false);
    });
  },
  beforeDestroy() {
    this.$bus.$off('update_targets');
  }
}
</script>
