<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('measure.configure')" class="connect">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('common.Configure') }}</span>
            </CCol>             
          </CRow>
        </CCardHeader>
        <CCardBody class="has_tabs pt-0 pb-0">
          <CRow class="pb-0">
            <CCol cols="12" lg="12" class="p-0">
              <b-tabs class="no_borders mb-0 no_padding" type="is-boxed" :animated="false" v-model="activeTab">
                <b-tab-item>
                  <template slot="header">
                    <i class="mr-1 fas fa-bullseye"/><span>{{ $t('measure.Targets') }}</span>
                  </template>
                  <template>
                    <Targets/>
                  </template>
                </b-tab-item>
                <b-tab-item>
                  <template slot="header">
                    <i class="mr-1 fas fa-redo"/><span>{{ $t('measure.Cycle') }}</span>
                  </template>
                  <template>
                    <Cycle/>
                  </template>
                </b-tab-item>
              </b-tabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import noPermission from '@/components/common/noPermission.vue';

import Targets from '@/views/measure/configure/Targets.vue';
import Cycle from '@/views/measure/configure/Cycle.vue';

export default {
  name: 'Configure',
  components: {
    noPermission,
    Targets,
    Cycle,
  },
  data() {
    return {
      modules: {},      
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      activeTab: 0
    }
  },
  methods: {
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
        // Get available email templates if user has permission
        if(this.platformPermissions.includes('core.emailtemplates') === true && this.$refs.measureMoments) this.$refs.measureMoments.getEmailTemplates();    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }      
  },
  mounted: function() {
    this.checkModules();
    this.getPlatformPermissions();    
  }
}
</script>